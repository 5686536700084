<template>
    <div class="container subscription-success-page">
        <h1 class="mb-3"> {{ settingData.event_register_thanks_title }} </h1>
        <p> {{ settingData.event_register_thanks_description }} </p>
        <router-link
            class="btn btn-theme mt-4 button-element back-to-events"
            to="events"
        >
            {{ $t('Event.BACK_TO_EVENTS') }}
        </router-link>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

  export default {
    name: 'SubscriptionSuccess',

    computed: {
         ...mapGetters({ settingData : 'getSettingData' }),
    }
  }

</script>